<template>
  <div>
    <v-autocomplete
      v-model="selectedItem"
      :items="listOptions"
      :loading="loading"
      :rules="rules"
      :filter="customFilter"
      item-value="id"
      item-text="text"
      label="Товар"
      placeholder="Начните ввод для поиска"
      no-data-text="Ничего не найдено"
      prepend-icon="mdi-database-search"
      clearable
      return-object
      @change="onChange"
    >
      <template v-slot:selection="{ attr, on, item, selected }">
        <v-chip
          v-bind="attr"
          :input-value="selected"
          label
          v-on="on"
        >
          <v-avatar left tile>
            <v-img
              :src="item.image | imageUrl('thumb', true)"
              class="rounded my-1"
            ></v-img>
          </v-avatar>
          <span v-text="item.text"></span>
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-avatar tile>
          <v-img
            :src="item.image | imageUrl('thumb', true)"
            class="rounded my-1"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content v-if="item.isActive===false" style="color:orange">
          <v-list-item-title v-text="item.code"></v-list-item-title>
          <v-list-item-subtitle v-text="item.name"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title v-text="item.code"></v-list-item-title>
          <v-list-item-subtitle v-text="item.name"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-card v-if="showDetails && selectedItem" class="d-flex" flat outlined>
      <v-card-title class="align-self-center">
        <v-img
          v-if="selectedItem.image"
          :src="selectedItem.image | imageUrl('thumb', true)"
          height="120"
          width="120"
          class="rounded my-1"
        ></v-img>
      </v-card-title>
      <v-card-text class="align-self-center">
        <div class="subtitle-1">{{ selectedItem.code }}</div>
        <div>{{ selectedItem.name }}</div>
        <div>{{ selectedItem.price | money }}</div>
        <div v-if="!selectedItem.isActive" style="color:orange">
          Товар не активный
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ItemService from '@/service/ItemService'
import ItemFilter from '@/model/item/ItemFilter'
import Page from '@/model/Page'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default () {
        return []
      }
    },
    showDetails: {
      type: Boolean,
      default() {
        return false
      }
    },
    selectedCallback: {
      type: Function,
      default() {
        return (item) => item
      }
    }
  },
  data: function () {
    return {
      listOptions: [],
      loading: false,
      selectedItem: null
    }
  },
  created() {
    const filter = ItemFilter.create()
    const page = Page.create({ size:10000 })

    this.loading = true
    ItemService.findForAutocomplete(filter, page)
      .then((result) => {

        this.listOptions = result.nodes.map((entry) => {
          const text = `${entry.code} - ${entry.name}`

          return Object.assign({}, entry, { text })
        })

        return this.listOptions
      })
      .then((options) => {
        if (this.value) {
          this.selectedItem = this.listOptions.find((o) => o.id === this.value)
        }
      })
      .finally(() => this.loading = false)
  },
  methods: {
    customFilter (item, queryText, itemText) {
      const nameText = item.name.toLowerCase()
      const codeText = item.code.toLowerCase()
      const searchText = queryText.toLowerCase()

      return nameText.indexOf(searchText) > -1 || codeText.indexOf(searchText) > -1
    },
    onChange() {
      const value = this.selectedItem?.id ?? null

      this.$emit('input', value)
      this.selectedCallback(this.selectedItem)
    }
  }
}

</script>
