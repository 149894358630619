var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{attrs:{"items":_vm.listOptions,"loading":_vm.loading,"rules":_vm.rules,"filter":_vm.customFilter,"item-value":"id","item-text":"text","label":"Товар","placeholder":"Начните ввод для поиска","no-data-text":"Ничего не найдено","prepend-icon":"mdi-database-search","clearable":"","return-object":""},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":selected,"label":""}},'v-chip',attr,false),on),[_c('v-avatar',{attrs:{"left":"","tile":""}},[_c('v-img',{staticClass:"rounded my-1",attrs:{"src":_vm._f("imageUrl")(item.image,'thumb', true)}})],1),_c('span',{domProps:{"textContent":_vm._s(item.text)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{attrs:{"tile":""}},[_c('v-img',{staticClass:"rounded my-1",attrs:{"src":_vm._f("imageUrl")(item.image,'thumb', true)}})],1),(item.isActive===false)?_c('v-list-item-content',{staticStyle:{"color":"orange"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.code)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.name)}})],1):_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.code)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.name)}})],1)]}}]),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}}),(_vm.showDetails && _vm.selectedItem)?_c('v-card',{staticClass:"d-flex",attrs:{"flat":"","outlined":""}},[_c('v-card-title',{staticClass:"align-self-center"},[(_vm.selectedItem.image)?_c('v-img',{staticClass:"rounded my-1",attrs:{"src":_vm._f("imageUrl")(_vm.selectedItem.image,'thumb', true),"height":"120","width":"120"}}):_vm._e()],1),_c('v-card-text',{staticClass:"align-self-center"},[_c('div',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.selectedItem.code))]),_c('div',[_vm._v(_vm._s(_vm.selectedItem.name))]),_c('div',[_vm._v(_vm._s(_vm._f("money")(_vm.selectedItem.price)))]),(!_vm.selectedItem.isActive)?_c('div',{staticStyle:{"color":"orange"}},[_vm._v(" Товар не активный ")]):_vm._e()])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }