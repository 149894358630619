export default class OrderLineUpdateForm {
  constructor(data) {
    this.itemId = data?.itemId
    this.price = data?.price ?? 0
    this.quantity = data?.quantity ?? 0
  }

  static create(data) {
    return new OrderLineUpdateForm(data)
  }

}
